const axios = require('axios');
const { baseUrlNew, buildAuthHeaders } = require('./settings.js');

const getListV2 = function (params) {
  return axios.post(baseUrlNew + `customer/list/v2`, params, {
    headers: buildAuthHeaders()
  });
};

const exportCustomers = function (params) {
  return axios.post(baseUrlNew + `customer/export`, params, {
    headers: buildAuthHeaders()
  });
};

const fetchCustomerDetails = function (params) {
  return axios.post(baseUrlNew + `customer/detail`, params, {
    headers: buildAuthHeaders()
  });
};

const fetchUserPropertyList = function (params) {
  return axios.post(baseUrlNew + `customer/props`, params, {
    headers: buildAuthHeaders()
  });
};

const fetchUserPropertyValueSuggetionsList = function (params) {
  return axios.post(baseUrlNew + `customer/props/values`, params, {
    headers: buildAuthHeaders()
  });
};

const fetchUserSuggestionListForPushTest = function (params) {
  return axios.post(baseUrlNew + `customer/suggestionForPushTest`, params, {
    headers: buildAuthHeaders()
  });
};

const searchCustomerForCampaignMessageSend = function (params) {
  return axios.post(baseUrlNew + `customer/search`, params, {
    headers: buildAuthHeaders()
  });
};

const validateCSVFile = function (formData) {
  let headers = buildAuthHeaders();
  headers['Content-Type'] = 'multipart/form-data';
  return axios.post(baseUrlNew + 'customer/csv/validate', formData, {
    headers: headers
  });
};

const uploadCustomersV2 = function (formData) {
  let headers = buildAuthHeaders();
  headers['Content-Type'] = 'multipart/form-data';
  return axios.post(baseUrlNew + 'customer/csv/uploadv2', formData, {
    headers: headers,
    timeout: 1000 * 600
  });
};

const createCustomerAttribute = function (params) {
  return axios.post(baseUrlNew + `customer/props/create`, params, {
    headers: buildAuthHeaders()
  });
};

const getCustomerSizeData = function () {
  return axios.get(baseUrlNew + `customer/size`, {
    headers: buildAuthHeaders()
  });
};

const getOverviewData = function () {
  return axios.get(baseUrlNew + `customer/overview`, {
    headers: buildAuthHeaders()
  });
};

const getOverviewOfInvalidContacts = function (params) {
  return axios.post(baseUrlNew + `customer/overview-invalid-contacts`, params, {
    headers: buildAuthHeaders()
  });
};

const getCustomerReachablity = function () {
  return axios.get(baseUrlNew + `customer/reachablity`, {
    headers: buildAuthHeaders()
  });
};

const unsubscribeCustomer = function (id, channel, status) {
  return axios.put(baseUrlNew + `customer/unsubscribe/${id}/${channel}?status=${status}`, {
    headers: buildAuthHeaders()
  });
};

const archiveCustomer = function (id) {
  return axios.delete(baseUrlNew + `customer/archive/${id}`, {
    headers: buildAuthHeaders()
  });
};

module.exports = {
  getOverviewData,
  getListV2,
  getOverviewOfInvalidContacts,
  exportCustomers,
  fetchCustomerDetails,
  fetchUserPropertyList,
  fetchUserPropertyValueSuggetionsList,
  validateCSVFile,
  // uploadCustomers,
  uploadCustomersV2,
  createCustomerAttribute,
  fetchUserSuggestionListForPushTest,
  searchCustomerForCampaignMessageSend,
  getCustomerSizeData,
  getCustomerReachablity,
  unsubscribeCustomer,
  archiveCustomer
};
