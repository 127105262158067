<template>
  <div id="createCampaignStepper" v-if="campaign">
    <el-row class="mainHeader">
      <el-col :span="8" class="campaignHeaderTitle">
        <a style="cursor: pointer" class="campaignHeaderTitle">
          <i @click="$router.push(`/campaigns/${campaign.channel}`)" class="el-icon-back"></i>
          &nbsp; Edit Campaign
        </a>
      </el-col>
      <el-col :span="16">
        <el-steps :active="step" align-center finish-status="success">
          <el-step v-for="(currentStep, index) in stepList" @click.native="onStepClicked(index)" v-bind:class="{ selectableStep: step > index }" :title="currentStep" v-bind:key="index"> </el-step>
        </el-steps>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { deleteCampaign } from '@/services/campaignv1.js';

export default {
  name: 'createCampaignStepper',
  props: {
    step: {
      type: Number,
      required: true
    },
    campaign: {
      type: Object,
      required: true
    },
    allowPublish: {
      type: Boolean,
      false: true,
      default: false
    }
  },
  data() {
    return {
      stepList: ['Basic Info', 'Target Audience', 'Campaign Content', 'Conversion Tracking', 'Review & Launch'],
      startingCampaign: false
    };
  },

  methods: {
    async deleteDraft() {
      this.$swal({
        title: 'Delete draft?',
        text: "You won't be able to undo this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(async () => {
        try {
          // Delete Campaign
          await deleteCampaign(this.campaign.id, this);
          this.successToast('Campaign Deleted.');
          this.$router.push(`/campaigns/${this.campaign.channel}`);
        } catch (err) {
          this.errorToast('Failed to delete draft. Please contact support. ');
        }
      });
    },

    onStepClicked(index) {
      console.log('Ya redirect man..', index);
      if (this.step > index) {
        if (index == 0) {
          // Redirect to basic info.
          this.$router.push({ name: 'campaignGeneralInfoUpdate', params: { id: this.campaign.id } });
        } else if (index == 1) {
          // Redirect to target audiance.
          this.$router.push({ name: 'campaignTargetAudiance', params: { id: this.campaign.id } });
        } else if (index == 2) {
          // Redirect to content.
          this.$router.push(`/campaign/update/${this.campaign.channel}/${this.campaign.id}`);
        } else if (index == 3) {
          // Redirect to goals.
          this.$router.push(`/campaign/update/goals/${this.campaign.id}`);
        } else if (index == 4) {
          // Redirect to review.
          this.$router.push(`/campaign/update/review/${this.campaign.id}`);
        } else {
          console.log('Ya skip redirect man..', index);
        }
      }
    }
  },

  beforeDestroy() {
    document.getElementById('subSideMenu').style.display = 'block';
  },

  created() {
    document.getElementById('subSideMenu').style.display = 'none';
  }
};
</script>

<style lang="scss">
@import 'src/assets/scss/_variables.scss';

#createCampaignStepper {
  background: white;
  border-bottom: 1px solid #ddd;
  background: #f8fcfd;

  .mainHeader {
    .campaignHeaderTitle {
      font-size: 20px;
      color: #555;
      line-height: 65px;
      padding-left: 10px;
      text-decoration: none;
    }

    .rightSideHEader {
      margin-top: 25px;
      padding-right: 20px;
      text-align: right;
    }
  }

  .el-steps {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 7px !important;

    border-bottom: 0px;
    border-radius: 5px 5px 0px 0px;

    .el-step {
      cursor: not-allowed;

      .el-step__main {
        .el-step__title {
          font-size: 12px;
          line-height: 27px;
        }
      }

      .is-process {
        color: $primary;

        .is-text {
          background: $primary;
          color: white;
          border: 1px solid $primary;
        }
      }
    }

    .selectableStep {
      cursor: pointer;
    }
  }
}
</style>
