const { getCampaignContent, updateCampaignContent } = require('../../services/campaignv1');

exports.fetchCampaignContent = async function (instance) {
  try {
    this.fetchingData = true;

    // Fetch Details
    let response = await getCampaignContent(instance.campaignId);

    instance.campaign = response.data.campaign;
    if (!instance.campaign) {
      instance.$router.replace({ name: '404' });
      return;
    }

    // Prepare Campaign Contents
    instance.content = response.data;
    // Handle web social proof message parsing.
    if (instance.content && instance.content.content && instance.content.content.on_site_content) {
      instance.content.content = JSON.parse(instance.content.content.on_site_content);
    } else if (instance.content && instance.content.content && instance.content.content.in_app_content) {
      instance.content.content = JSON.parse(instance.content.content.in_app_content);
    }

    instance.fetchingData = false;

    // Render campaign content.
    delete instance.content.campaign;
    requestAnimationFrame(() => {
      instance.$refs.contentBuilder.renderCampaignContent(instance.content, instance.campaign);
      instance.hasUnsavedChanges = false;
    });
  } catch (err) {
    instance.reportError(err);
    instance.errorToast('Failed to read campaign content. Please contact support.');
    instance.fetchingData = false;
  }
};

exports.validateAndSave = async (instance) => {
  // Save Content
  instance.savingCampaign = true;
  try {
    // Validate Content
    let isValid = await instance.$refs.contentBuilder.validateForm();
    if (!isValid) {
      instance.savingCampaign = false;
      return false;
    }

    // Read Content
    let content = await instance.$refs.contentBuilder.readProcessedContentV1();

    // Save Content
    await updateCampaignContent(instance.campaignId, content);

    instance.savingCampaign = false;
    return true;
  } catch (err) {
    instance.savingCampaign = false;
    instance.errorToast('Failed to save changes. Please contact support.');
    instance.reportError(err);
    return false;
  }
};

exports.goBack = async (instance) => {
  try {
    let result = await instance.$confirm('You have unsaved changes, save and proceed?', 'Save Changes?', {
      distinguishCancelAndClose: true,
      confirmButtonText: 'Save Changes',
      cancelButtonText: 'Ignore Changes'
    });

    // Save changes
    result = await exports.validateAndSave(instance);
    if (!result) return;
  } catch (err) {
    // Igore changes
  }

  // Go To Previous
  instance.savingCampaign = true;
  instance.$router.push({ name: 'campaignTargetAudiance', params: { id: instance.campaignId } });
};

exports.onGoNext = async (instance) => {
  let result = await exports.validateAndSave(instance);
  if (result) {
    // Redirect to review page.
    instance.$router.push(`/campaign/update/goals/${instance.campaignId}`);
  }
};

exports.saveDraft = async (instance) => {
  let result = await exports.validateAndSave(instance);
  if (result) {
    instance.successToast('Draft Saved.');
  }
};
