<template>
  <el-row class="campaignStepFooter" v-if="campaign">
    <el-col :span="12">
      <el-button class="prevButton" type="warning" size="big" @click="onPrev" v-if="step != 0">
        <i class="el-icon-back"></i>
        &nbsp; Previous Step
      </el-button>
    </el-col>
    <el-col :span="12" class="nextButtons">
      <el-button type="danger" icon="el-icon-delete" plain :loading="savingCampaign" @click="deleteDraft">Delete Draft</el-button>
      &nbsp;
      <template v-if="!showPublish">
        <el-button type="success" @click="onSaveDraft"> Save Draft </el-button>
        &nbsp;
        <el-button type="primary" @click="onNext()">
          Save & Continue &nbsp;
          <i class="el-icon-arrow-right el-icon-right"></i>
        </el-button>
      </template>
      <template v-else>
        <el-button type="primary" @click="onPublish()">
          Launch Campaign
          <i class="el-icon-arrow-right el-icon-right"></i>
        </el-button>
      </template>
    </el-col>
  </el-row>
</template>

<script>
import { deleteCampaign } from '@/services/campaignv1.js';

export default {
  name: 'createCampaignStepper',
  props: {
    step: {
      type: Number,
      required: true
    },
    campaign: {
      type: Object,
      required: true
    },
    showPublish: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      stepList: ['Basic Info', 'Target Audience', 'Campaign Content', 'Campaign Goals', 'Review & Launch'],
      savingCampaign: false
    };
  },

  methods: {
    onSaveDraft() {
      this.$emit('onSaveDraft');
    },

    onNext() {
      this.$emit('onNext');
    },
    onPublish() {
      this.$emit('onPublish');
    },

    onPrev() {
      this.$emit('onPrev');
    },

    async deleteDraft() {
      this.$swal({
        title: 'Delete draft?',
        text: "You won't be able to undo this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(async () => {
        try {
          // Delete Campaign
          this.savingCampaign = true;
          await deleteCampaign(this.campaign.id, this);
          this.successToast('Campaign Deleted.');
          this.$router.push(`/campaigns/${this.campaign.channel}`);
        } catch (err) {
          this.savingCampaign = false;
          this.errorToast('Failed to delete draft. Please contact support. ');
        }
      });
    }
  },

  beforeDestroy() {
    document.getElementById('subSideMenu').style.display = 'block';
  },

  created() {
    document.getElementById('subSideMenu').style.display = 'none';
  }
};
</script>

<style lang="scss">
@import 'src/assets/scss/_variables.scss';

.campaignStepFooter {
  width: 100%;
  position: fixed;
  bottom: 0px;
  padding: 15px 25px;
  background: white;
  // padding: 7px 15px;
  border-top: 1px solid #ddd;
  border-bottom: 0px;
  padding-right: 160px;
  z-index: 99;

  .nextButtons {
    float: right;
    text-align: right;
  }
}
</style>
